<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Markers from "./markers";

/**
 * Google-map component
 */
export default {
  components: { Layout, PageHeader, Markers },
  data() {
    return {
      title: "Affectation à un agent en service",
      items: [
        {
          text: "Interventions",
          href: "/list-intervention",
        },
        {
          text: "Affecter un agent",
          active: true,
        },
      ],
    };
  },
  methods: {
   
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Position des agents! Veillez sélectionner un agent</h4>
            <!-- Map with markers -->
            <Markers class="leaflet-map"/>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
