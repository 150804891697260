<script>
import { LMap, LMarker, LTileLayer, LIcon } from "@vue-leaflet/vue-leaflet";
import { latLng } from "leaflet";
import Swal from 'sweetalert2'
import router from '../../index';
import { reqMethods } from '../../../state/helpers';

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon
    },
    data() {
        return {
            zoom: 12,
            maxZoom: 25,
            center: latLng(6.2012158618, 1.15410990516),
            url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            staticAnchor: [16, 37],
            reqError: null,
            markers: [
                {
                    id: "m1",
                    position: { lat: 6.505, lng: 1.329 },
                    name: "KOUADJO Paul",
                    typeAgent: "moto",
                },
                {
                    id: "m2",
                    position: { lat: 6.8905, lng: 1.169 },
                    name: "BARATE Kokou",
                    typeAgent: "car",

                },
                {
                    id: "m3",
                    position: { lat: 7.005, lng: 1.09789 },
                    name: "SONO Raoul",
                    typeAgent: "pied",

                },
                {
                    id: "m4",
                    position: { lat: 7.7605, lng: 1.09 },
                    name: "Patrick VINCENT",
                    typeAgent: "moto",

                }
            ],
            selectedPosition: [],
        };
    },
    methods: {
        ...reqMethods,
        getAgentPosition() {
            const that = this;
            this.getRequest('/agent/agents/')
            .then(
                function (response) {
                    that.markers = response.data;
                },
                function (error) {
                    that.reqError = error;
                }
            )
        },
   
        position(data) {
            Swal.fire({
                title: data.name,
                text: "Souhaitez-vous affecter cette intervention à cet agent ?",
                imageUrl: require("@/assets/images/logo.png"),
                imageHeight: 40,
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Oui, Affecter !",
            }).then(result => {
                if (result.value) {
                    router.push({ path: '/intervention-progress' });
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Affectation effectuée avec succès",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            });
        }
    },
    mounted() {
        // this. getAgentPosition();
    },
};
</script>

<template>
    <l-map style="height: 500px; width: 100%" :zoom="zoom" :center="center">
        <l-tile-layer :url="url" :maxZoom="maxZoom" />
        <l-marker v-for="marker in markers" :key="marker.id" :visible="marker.visible" :draggable="marker.draggable"
            :lat-lng="marker.position" @click="position(marker)">
            <l-icon v-if="marker.typeAgent == 'moto'" :icon-anchor="staticAnchor">
                <img src="@/assets/images/leaflet/motorbike.png" />
            </l-icon>
            <l-icon v-if="marker.typeAgent == 'car'" :icon-anchor="staticAnchor">
                <img src="@/assets/images/leaflet/police.png" />
            </l-icon>
            <l-icon v-if="marker.typeAgent == 'pied'" :icon-anchor="staticAnchor">
                <img src="@/assets/images/leaflet/walking.png" />
            </l-icon>
        </l-marker>
    </l-map>
</template> 